<template>
  <div>
  <div :style="getElementStyle" v-show="data.properties.filed_content != 'Hide'">
    <div v-loading="loading" v-if="!isDefalutPosq" style="display: flex">
      <div style="display: flex">
        <label
          for="text"
          v-if="
            hasLabel &&
            data.properties.filed_content != 'Hide' &&
            !data.detailed_view
          "
          :style="getLabelStyles"
          style="margin-right: 5px; white-space: nowrap"
          >{{ label||data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel && !data.detailed_view"
          style="color: red; font-weight: bold; margin-right: 5px"
          >*</span
        >
        <span style="margin-right: 5px">
          <el-popover
            v-if="data.description && !fromRepeatable && hasLabel"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span
          v-if="
            data.allow_quick_add &&
            !isViewCopy &&
            !fromRepeatable &&
            (isFromDocument || !checkReadonly())
          "
          style="margin-right: 5px"
        >
          <quickEntityAddWidget
            :currentEntity="currentEntity"
            :parentForm="form"
            :parentFields="fieldsData"
            @onNewEntity="setQuickAddEntityDataAsValue"
          ></quickEntityAddWidget>
        </span>
      </div>
      <div>
        <el-col
          :span="
            showLabel && isDefalutPosq ? (data.allow_quick_add ? 24 : 23) : 23
          "
        >
          <div
            v-if="
              !isViewCopy && data.properties.filed_content != 'Hide' && !refresh
            "
            :style="getStyle"
          >
            <div v-if="data.detailed_view">
              <el-button
                type="text"
                v-if="data.detailed_view"
                :underline="false"
                @click="openDetailedSelect"
                :disabled="checkReadonly()"
                :loading="optionsLoading"
              >
                {{ label||data.label}}
              </el-button>
              <div
                v-if="
                  form[data.key] && form[data.key] && form[data.key + '/name']
                "
              >
                {{ form[data.key + "/name"] }}
              </div>
            </div>
            <el-row v-else>

              <el-col  :span="data.properties.qr_code_scanning ? 22 : 24">
                <el-select
                  v-model="form[data.key]"
                  :disabled="isFromDocument ? false : checkReadonly()"
                  :placeholder="data.placeholder || data.label"
              clearable
                  collapse-tags
                  :value-key="'entity_data_id'"
                  @change="checkEntityFields(form[data.key], true)"
                  style="
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                  "
                  filterable
                  :suffix-icon="data.properties.el_icon"
                  :loading="optionsLoading"
                  :remote-method="searchEntityData"
                  remote
                  @clear="handleClear"
                  loading-text="Loading..."
                  :multiple="data.allow_multiple || false"
                  class="centered-text-input"
                >
                  <template v-if="!data.is_entity_field">
                    <el-option
                      value="ALL"
                      label="All"
                      v-if="data.show_all_option"
                    ></el-option>
                    <el-option
                      v-for="(temp, i) in entitiesData"
                      :key="i"
                      :value="temp._id"
                      :label="getLabel(temp, i)"
                    ></el-option>
                    <el-option
                      value="LOADMORE"
                      label="Load more..."
                      v-if="!isSearching && hasMore"
                    ></el-option>
                  </template>

                  <template
                    v-if="
                      data.is_entity_field &&
                      form[data.key + '/default'] &&
                      form[data.key + '/default'].length
                    "
                  >
                    <el-option
                      v-for="(temp, i) in entitiesData.filter(
                        (e) => form[data.key + '/default'].indexOf(e._id) != -1
                      )"
                      :key="i"
                      :value="temp._id"
                      :label="getLabel(temp, i)"
                    ></el-option>
                  </template>
                </el-select>
                </el-col>
              <el-col v-if="data.properties.qr_code_scanning && !isView" :span="data.properties.qr_code_scanning ? 2 : 24">
              <span class="qrCodeBlock">
                <el-popover v-if="data.properties.qr_code_scanning" placement="top-start" trigger="hover"
                  :content="qrDescription">
                  <i class="el-icon-camera size-2em" slot="reference" @click="enableQRReader"></i>
                </el-popover>
              </span>
              </el-col>
            </el-row>
            
            <span v-if="showWarningText" class="warning-span">
              {{ showWarningText }}
            </span>
          </div>
          <p
            v-if="isViewCopy && data.properties.filed_content != 'Hide'"
            :style="fromRepeatable ? '' : getStyle"
          >
            <template
              v-if="
                form[data.key] && form[data.key] !== 'ALL' && form[data.key] && form[data.key + '/name']
              "
              >{{ form[data.key + "/name"] }}</template
            >
            <template
              v-else-if="data.show_all_option && form[data.key] == 'ALL'"
            >
              {{ 'All' }}
            </template>
            <template v-else>-</template>
          </p>
        </el-col>
        <el-col
          :span="4"
          v-if="data.allow_quick_add && !isViewCopy && fromRepeatable"
        >
          <quickEntityAddWidget
            :currentEntity="currentEntity"
            :parentForm="form"
            :parentFields="fieldsData"
            @onNewEntity="setQuickAddEntityDataAsValue"
            :fromRepeatable="fromRepeatable"
          ></quickEntityAddWidget>
        </el-col>
      </div>
    </div>

    <div v-loading="loading" v-else>
      <el-row align="middle">
        <el-col
          v-if="!data.properties.hideLabel"
          :span="
            showLabel && !isDefalutPosq
              ? 4
              : data.description
              ? data.allow_quick_add
                ? 20
                : 20
              : data.allow_quick_add
              ? 20
              : 24
          "
          style="display: flex"
          :style="computedStyles"
        >
          <div class="d-flex">
            <label
              for="text"
              v-if="
                hasLabel &&
                data.properties.filed_content != 'Hide' &&
                !data.detailed_view
              "
              :style="getLabelStyles"
              style="margin-right: 5px"
              >{{ label||data.label }}</label
            >
            <span
              v-if="
                data.validations.required && hasLabel && !data.detailed_view
              "
              style="color: red; font-weight: bold; margin-right: 5px"
              >*</span
            >
            <!-- <span v-if="currentEntity && currentEntity._id">
              <entityDataWidget
                :currentEntity="currentEntity"
                :field="data"
                :filters="filters"
              >
              </entityDataWidget>
            </span> -->
            <span style="margin-right: 5px">
              <el-popover
                v-if="data.description && !fromRepeatable && hasLabel"
                placement="top-start"
                trigger="hover"
                :content="data.description"
              >
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
            </span>
          </div>
          <span
            v-if="
              data.allow_quick_add &&
              !isViewCopy &&
              !fromRepeatable &&
              (isFromDocument || !checkReadonly())
            "
            style="margin-right: 5px"
          >
            <quickEntityAddWidget
              :currentEntity="currentEntity"
              :parentForm="form"
              :parentFields="fieldsData"
              @onNewEntity="setQuickAddEntityDataAsValue"
            ></quickEntityAddWidget>
          </span>
        </el-col>
        <el-col
          v-else
          :span="showLabel  ? 4 : data.description ? 20 : 24"
          ref="colRef"
          :style="computedStyles"
        >
          <span
            v-if="hasLabel"
            style="
              color: white;
              display: inline-block;
              height: 20px;
              opacity: 0;
            "
          ></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="
            showLabel && isDefalutPosq ? (data.allow_quick_add ? 24 : 23) : 23
          ">
          <div
            v-if="
              !isViewCopy && data.properties.filed_content != 'Hide' && !refresh
            "
            :style="getStyle"
          >
            <div v-if="data.detailed_view">
              <el-button
                v-if="data.detailed_view"
                type="text"
                :underline="false"
                @click="openDetailedSelect"
                :disabled="checkReadonly()"
                :loading="optionsLoading"
              >
                {{ label||data.label }}
              </el-button>
              <div
                v-if="
                  form[data.key] && form[data.key] && form[data.key + '/name']
                "
              >
                {{ getSplitedLabel(form[data.key + "/name"]) }}
              </div>
            </div>
            <div
              v-else-if="
                optionsLoading && form[data.key] && form[data.key].length
              "
              v-loading="optionsLoading"
              :style="`height: ${data.height} px;`"
            >
              {{
                form[data.key + "/name"] &&
                !form[data.key + "/name"].includes("Data ")
                  ? form[data.key + "/name"]
                  : ""
              }}
            </div>
            <el-row v-else>
              <el-col  :span="data.properties.qr_code_scanning ? 22 : 24">
                <el-select
              v-model="form[data.key]"
              :disabled="isFromDocument ? false : checkReadonly()"
              clearable
              :placeholder="data.placeholder || data.label"
              collapse-tags
              :value-key="'entity_data_id'"
              @change="checkEntityFields(form[data.key], true)"
              :style="getStyle" 
              filterable
              :suffix-icon="data.properties.el_icon"
              :loading="optionsLoading"
              :remote-method="searchEntityData"
              @clear="handleClear"
              :required="data.validations.required"
              remote
              loading-text="Loading..."
              :multiple="data.allow_multiple || false"
             :class="{'highlight-border': highlight}"
            >
              <template v-if="!data.is_entity_field">
                <el-option
                  value="ALL"
                  label="All"
                  v-if="data.show_all_option"
                ></el-option>
                <el-option
                  v-for="(temp, i) in entitiesData"
                  :key="i"
                  :value="temp._id"
                  :label="getLabel(temp, i)"
                ></el-option>
                <el-option
                  value="LOADMORE"
                  label="Load more..."
                  v-if="!isSearching && hasMore"
                ></el-option>
              </template>

              <template
                v-if="
                  data.is_entity_field &&
                  form[data.key + '/default'] &&
                  form[data.key + '/default'].length
                "
              >
                <el-option
                  v-for="(temp, i) in entitiesData.filter(
                    (e) => form[data.key + '/default'].indexOf(e._id) != -1
                  )"
                  :key="i"
                  :value="temp._id"
                  :label="getLabel(temp, i)"
                ></el-option>
              </template>
            </el-select>
                </el-col>
                <el-col v-if="data.properties.qr_code_scanning && !isView" :span="data.properties.qr_code_scanning ? 2 : 24">
                  <span class="qrCodeBlock">
                    <el-popover v-if="data.properties.qr_code_scanning" placement="top-start" trigger="hover"
                      :content="qrDescription">
                      <i class="el-icon-camera size-2em" slot="reference" @click="enableQRReader"></i>
                    </el-popover>
                  </span>
                  </el-col>
            </el-row>
            <span v-if="showWarningText" class="warning-span">
              {{ showWarningText }}
            </span>
          </div>
          <p
            v-if="isViewCopy && data.properties.filed_content != 'Hide'"
            :style="fromRepeatable ? '' : getStyle"
            style="font-weight: normal;"
          >
            <template
              v-if="
                form[data.key] && form[data.key] !== 'ALL' && form[data.key + '/name']
              "
              >{{ form[data.key + "/name"] }}</template
            >
            <template
              v-else-if="data.show_all_option && form[data.key] == 'ALL'"
            >
              {{ 'All' }}
            </template>
            <template v-else>-</template>
          </p>
        </el-col>
        <el-col
          :span="4"
          v-if="data.allow_quick_add && !isViewCopy && fromRepeatable"
        >
          <quickEntityAddWidget
            :currentEntity="currentEntity"
            :parentForm="form"
            :parentFields="fieldsData"
            @onNewEntity="setQuickAddEntityDataAsValue"
            :fromRepeatable="fromRepeatable"
          ></quickEntityAddWidget>
        </el-col>
      </el-row>
    </div>

    <dialog-component
      :visible="entityDetailedViewModal"
      :title="
        'Select ' +
        (currentEntity && currentEntity.name ? currentEntity.name : '')
      "
      @before-close="resetEntityDetailedView"
      :fullscreen="true"
      :containerMaxHeight="'90vh'"
      :isShowFooter="false"
    >
      <div>
        <EntityFieldDetailedView
          :currentEntity="currentEntity"
          :data="entitiesData"
          :fieldsData="fieldsData"
          :field="data"
          :form="form"
          :resetViewType="resetView"
          v-on:close="resetEntityDetailedView"
          v-on:rows-selected="checkEntityFields"
          v-on:data-table-selected="updateEntityDataTable"
          :isFromEntityExecutePopUp = "true"
        >
        </EntityFieldDetailedView>
      </div>
    </dialog-component>

    <dialog-component
        v-loading="qrLoader"
        :title="modalTitle"
        :visible="showQrReader"
        @before-close="cancelQRPopup"
        :containerWidth = "'50vw'"
        :containerMaxHeight = "'70vh'"
      >
      <QRReader v-if="showQrReader" :data="data"
      :form="form" :isFromTemplates="true"
      @before-close="cancelQRPopup" />


      </dialog-component>


  </div>
</div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
//import quickEntityAddWidget from "@/components/widgets/quickEntityAddWidget";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { makeSlug } from "../../../helpers/appHelper";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import { bus } from "../../../main";
import AutomationHelper from "@/mixins/AutomationHelper";
import moment from "moment";
// import entityDataWidget from "@/components/widgets/EntityDataDetailedView";
//import EntityFieldDetailedView from "@/components/widgets/EntityFieldDetailedView.vue";
export default {
  mixins: [
    AutomationHelper,
    userPermissionsHelper,
    CustomDashboardConfig,
    TemplateBuilderhelper,
  ],
  components: {
    //quickEntityAddWidget,
    quickEntityAddWidget: () => import("@/components/widgets/quickEntityAddWidget"),
    // entityDataWidget,
    //EntityFieldDetailedView,
    EntityFieldDetailedView: () => import("@/components/widgets/EntityFieldDetailedView"),
    DialogComponent: () => import("@/components/customComponents/CustomDialog.vue"),
    QRReader:()=>import("./QR_Reader.vue"),
  },
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isEntityField",
    "parentEntityId",
    "parentEntityField",
    "isFromDocument",
    "fromRepeatable",
    "company_id",
    "document_id",
    "childRelationshipsData",
    "checkIsDisabled",
    "profilePage",
    "index",
    "dataTableRowIndex",
    "parentFormData",
    "fieldsData",
    "autoFillEntityData",
    "entityDataExists",
    "autoFillPrefeeredEntityData",
    "colorFields",
    "formbuilderDetails",
    "highlight"
  ],
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      // isDefalutPos: true,
      entitiesData: [],
      maxSelection: 1,
      entityId: null,
      templateFields: [],
      templatesData: [],
      currentEntity: {},
      loading: false,
      primaryFields: [],
      allEntityDataList: [],
      filters: [],
      filtersData: {},
      parentFiltersData: {},
      currentEntityRelationship: {},
      realtionshipFilter: {},
      previousFormData: {},
      currentMenu: null,
      loginUserEntityRelationships: [],
      dataIds: [],
      previousParentFormData: {},
      parentDataField: {},
      limit: 500,
      page: 1,
      optionsLoading: false,
      hasMore: false,
      isSearching: false,
      search_string: "",
      update_from_parent: false,
      loadMore: false,
      showWarningText: "",
      refresh: false,
      default_data_ids: [],
      allEntityFields: [],
      entityDetailedViewModal: false,
      preferred_filters: [],
      preferred_data: [],
      resourceFilter: null,
      resourceFilterData: null,
      resetView: false,
      isViewCopy : false,
      //QR Code 
      qrDescription:'Scan QR/ Barcode',
      showQrReader:false,
      modalTitle:' Scan Here',
      qrLoader:false,
    };
  },
  computed: {
    getStyle() {
      return `height:${this.data.height - 50}px; width: ${this.data.width}px;`;
    
    },
    getEntityPrimaryFields() {
      if (this.data.primary_fields && this.data.primary_fields.length) {
        return this.data.primary_fields;
      }
      if (this.currentEntity?.primaryFields) {
        return [
          this.currentEntity?.primaryFields[0].template_id +
            "#" +
            this.currentEntity?.primaryFields[0].key,
        ];
      }
      return [];
    },
    // thaurni start
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    // Tharuni End
    ...mapGetters("companyEntities", [
      "getSingleCompanyEntity",
      "getSingleCompanyEntityWithHash",
    ]),

    ...mapGetters("entitiesData", [
      "getEntityRecordsForTableWithHash",
      "getEntityRecordsForFieldsWithHash",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
      "getAllEntitiesInTemplate"
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getGlobalFiltervalue",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getCurrentMenu",
      "getEntityMenu",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    checkGlobalFilter() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        if (
          filter?.entity_id == this.data.entity_id &&
          filter.apply_on
            .flatMap((e) => e.split("#")[0])
            .indexOf(this.data.parent_entityId) != -1
        ) {
          return filter;
        }
        return null;
      }
      return null;
    },
    //This method is not there whomever implemented this
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  async mounted() {
    // thaurni start
    this.isViewCopy = this.isView
    if(this.data.always_execute && this.data.always_execute == true){
      this.isViewCopy = false
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
    if (this.data.default_value) {
      this.form[this.data.key] = this.data.default_value;
    }
    if(this.data.default_mapped_field && this.data.data_table_key !== undefined){
      if(this.data.replace_value || !this.form[this.data.key]){
        if (this.data?.allow_multiple) {
          this.$set(this.form, this.data.key, [this.parentFormData[this.data.default_mapped_field]]);
          await this.checkEntityFields([this.parentFormData[this.data.default_mapped_field]], true);
        } else {
          this.$set(this.form, this.data.key, this.parentFormData[this.data.default_mapped_field]);
          await this.checkEntityFields(this.parentFormData[this.data.default_mapped_field], true);
        }
      }
    }
    // tharuni end

    this.entityId = this.data.entity_id;
    this.maxSelection =
      this.data.global_variable_entity_select_type === "single" ? 1 : 0;
    // await Promise.all([
    //   // this.fetchEntityData(),
    //   this.fetchEntityById(),
    // ]);
    if (
      this.form[this.data.key] &&
      this.data.allow_multiple &&
      typeof this.form[this.data.key] == "string"
    ) {
      this.$set(this.form, this.data.key, [this.form[this.data.key]]);
    }
    if (!this.isViewCopy) {
      if (
        this.autoFillEntityData &&
        this.autoFillEntityData.selectedEntity &&
        this.autoFillEntityData.update
      ) {
        this.entitiesData = this.autoFillEntityData.data || [];
        this.currentEntity = this.autoFillEntityData.selectedEntity;
        this.allEntityFields = [];
        this.allEntityFields = this.getAllFieldsFromEntity(this.currentEntity);
        this.filters = JSON.parse(JSON.stringify([...this.data.filters]));
        this.mapFiltersWithData();
        let preferred_filter = this.data.filters.find(
          (e) => e.preferred_filter
        );
        if (preferred_filter) {
          this.preferred_filters = [...this.data.filters];
        }
        if (
          this.preferred_filters &&
          this.preferred_filters.length &&
          !this.form[this.data.key]
        ) {
          this.optionsLoading = true;
          await this.fetchPreferredData();
          this.optionsLoading = false;
          if (this.preferred_data.length) {
            if (this.data?.allow_multiple) {
              this.$set(this.form, this.data.key, this.preferred_data);
              await this.checkEntityFields(this.preferred_data, true);
            } else {
              this.$set(this.form, this.data.key, this.preferred_data[0]);
              let label = this.getLabel(this.preferred_data[0], 0);
              this.$set(this.form, this.data.key + "/name", label);
              await this.checkEntityFields(this.preferred_data[0], true);
            }
          }
        }
        this.loading = false;
      } else {
        this.loading = true;
        await this.fetchUserRelationships();
        await this.fetchEntityById();
        this.previousFormData = JSON.parse(JSON.stringify(this.form));
        this.previousParentFormData = JSON.parse(
          JSON.stringify(this.parentFormData || {})
        );
      }
    }
    // await this.fetchEntityData();
    // this.loading = false;
    // we have to fetch entities data
  },
  methods: {
    getSplitedLabel(label) {
      let splitData = label.split(",");
      if (splitData && splitData.length > 3) {
        return splitData.slice(0, 3).join(",") + "...";
      }
      return label;
    },
    updateEntityDataTable(data, field) {
      this.$set(this.form, field, data);
      let selectedData = [
        ...new Set(data.flatMap((e) => e.parentDataId) || []),
      ];
      let label =
        [...new Set(data.flatMap((e) => e.parentDataName) || [])].join(",") ||
        "";
      this.$set(this.form, this.data.key, selectedData);
      this.$set(this.form, this.data.key + "/name", label);
    },
    openDetailedSelect() {
      this.entityDetailedViewModal = true;
    },
    resetEntityDetailedView() {
      this.resetView=!this.resetView;
      this.entityDetailedViewModal = false;
    },
    getFieldProperty(fields, key, property) {
      if (fields.length && key && property) {
        let field = fields.find((e) => e.key == key);
        if (field && field[property]) {
          return field[property];
        }
        return "";
      }
      return "";
    },
    handleClear() {
      this.isSearching = false;
      this.search_string = "";
      this.page = 1;
      this.fetchEntitiesDataForTable();
    },
    searchEntityData(query) {
      if (query) {
        this.isSearching = true;
        if (query.length > 2) {
          this.search_string = query;
          this.page = 1;
          this.fetchEntitiesDataForTable();
        }
      } else {
        this.isSearching = false;
        this.search_string = "";
        this.page = 1;
        this.fetchEntitiesDataForTable();
      }
    },
    async fetchUserRelationships() {
      let id =
        this.getActiveContactType?.contact_type?._id ||
        this.getAuthenticatedUser?.contact_type;
      if (id) {
        await this.$store.dispatch(
          "entityRelationships/fetchEntityRelationships",
          {
            entity_id: id,
          }
        );
        if (this.getEntityRelationships) {
          this.loginUserEntityRelationships = [...this.getEntityRelationships];
        }
      }
    },
    rulesEventEmitter() {
      this.$emit("applyFormRules");
    },
    async getValue() {
      let filteredFields = [];
      if (this.filters && this.filters.length) {
        this.filters.forEach((e) => {
          if (
            (e.value_field &&
              (e.data_source == "self_field" || e.data_source == "TEMPLATE")) ||
            e.data_source == "parent_entity_data_id" ||
            (e.value_field && e.data_source == "from_date") ||
            (e.field == "UNIQUE" && (e.unique_field || e.limit_field))
          ) {
            filteredFields.push(e.value_field);
          }
        });
      }
      this.update_from_parent = true;
      if (filteredFields.length) {
        this.updateFilters();
      } else {
        // await this.$store.dispatch(
        //   "entityRelationships/fetchParentEntityNestedRelationshipData",
        //   params
        // );

        // this.parentRelatedChildEntityData = {
        //   ...this.getParentEntityNestedRelationshipData,
        // };
        await this.fetchEntitiesDataForTable();
      }
    },
    async fetchEntityById() {
      // await Promise.all([
      // await this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
      //   entity_id: this.entityId,
      // }),,
      // ]);
      let entityData;
      if(this.getAllEntitiesInTemplate && this.getAllEntitiesInTemplate[this.entityId]){
        entityData = this.getAllEntitiesInTemplate[this.entityId];
      }else{
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: this.entityId,
        });
        entityData = this.getEntityById
      }

      if (entityData) {
        this.currentEntity = entityData;
        this.allEntityFields = [];
        this.allEntityFields = this.getAllFieldsFromEntity(this.currentEntity);
        if (
          (this.$route.params.menuId ||
            this.$route.query.menu_id ||
            this.getCurrentMenu) &&
          this.getEntityMenu?.menu_list
        ) {
          if (this.getCurrentMenu) {
            this.currentMenu = this.getCurrentMenu;
          } else {
            let id = this.$route.params.menuId || this.$route.query.menu_id;
            this.getEntityMenu.menu_list.forEach((e) => {
              if (e._id == id) {
                this.currentMenu = e;
              } else if (e.children) {
                (e.children || []).forEach((el) => {
                  if (el._id == id) {
                    this.currentMenu = el;
                  }
                });
              }
            });
          }
        }
        let currentRelationship = (
          this.loginUserEntityRelationships || []
        ).find(
          (e) =>
            (e?.representation == "FIELD" &&
              e?.field_id == this.data?._id &&
              e.child_entity_id === this.data.entity_id &&
              e.parent_entity_id == this.data?.parent_entityId) ||
            (e.child_entity_id == this.getAuthenticatedUser?.contact_type &&
              this.getAuthenticatedUser?.contact_type == this.data.entity_id)
        );
        if (
          this.getAuthenticatedUser?.contact_type &&
          currentRelationship &&
          this.getAuthenticatedUser?.account_data_id &&
          this.getAuthenticatedUser?.contact_type == this.data.entity_id &&
          this.formbuilderDetails?.form_builders_owner == 'ENTITY' &&
          this.formbuilderDetails?.entity_id == this.getAuthenticatedUser?.contact_type
        ) {
          this.filters = [];
          this.dataIds = [];
          this.dataIds = [this.getAuthenticatedUser?.account_data_id];
        } else if (
          this.getAuthenticatedUser?.is_contact &&
          currentRelationship &&
          currentRelationship?.representation == "FIELD" &&
          currentRelationship?.field_id == this.data?._id &&
          this.currentMenu?.relational_entity == this.data?.parent_entityId &&
          this.getActiveContactType?.account_data_id
        ) {
          this.filters = [];
          this.dataIds = [];
          this.dataIds = [this.getActiveContactType?.account_data_id];
        } else if (
          this.entityDataExists &&
          this.checkReadonly() &&
          this.data?.parent_entityDataId
        ) {
          this.filters = [];
        } else if (this.data && this.data.filters) {
          let preferred_filter = this.data.filters.find(
            (e) => e.preferred_filter
          );
          if (preferred_filter) {
            this.preferred_filters = [...this.data.filters];
          }
          this.filters = [...this.data.filters];
          this.resourceFilter = (this.filters || []).find(
            (e) => e.field == "RESOURCE_MANAGEMENT"
          );
          this.mapFiltersWithData();
        }
        // this.setRelationshipDetails();
        await this.getValue();
      } else {
        this.loading = false;
      }
    },
    mapFiltersWithData() {
      this.filters.map((e) => {
        if (e.field !== "UNIQUE" && e.unique_field) e.unique_field = "";
        if (
          e.unique_field &&
          e.unique_field.includes("#") &&
          this.data?.template_id
        ) {
          e.unique_field =
            this.data.template_id + "#" + e.unique_field.split("#")[1];
        }
        if (e.field != "UNIQUE") {
          if (
            (e.operator == "in" || e.operator == "nin") &&
            typeof e.value != "object"
          ) {
            e.value = [e.value];
            this.$set(this.filtersData, e.value_field, [e.value]);
          } else {
            this.$set(this.filtersData, e.value_field, e.value);
          }
        } else if (e.unique_field) {
          if (e.unique_field.includes("#")) {
            this.$set(
              this.filtersData,
              e.unique_field.split("#")[1],
              e.unique_value
            );
          } else {
            this.$set(this.filtersData, e.unique_field, e.unique_value);
          }
          if (e.limit_field && e.limit_field.includes("#")) {
            this.$set(
              this.filtersData,
              e.limit_field.split("#")[1],
              e.current_limit_field
            );
          }
        }

        if (e && e.data_source == "self_field" && e.value_field && !e.value) {
          e.value = this.form[e.value_field];
          if (
            this.parentFormData &&
            this.parentFormData[e.value_field] &&
            !this.form[e.value_field]
          ) {
            e.value = this.parentFormData[e.value_field];
          }
        }
        if (e?.data_type == "DATE" && e?.data_source == "TEMPLATE") {
          e.value = this.convertDate(this.form[e.value_field], "23:59:00.000Z");
          if (
            this.parentFormData &&
            this.parentFormData[e.value_field] &&
            !this.form[e.value_field]
          ) {
            e.value = this.convertDate(
              this.parentFormData[e.value_field],
              "23:59:00.000Z"
            );
          }
        }
      });
    },
    convertDate(value, time) {
      let date = moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      if (date && date.includes("T")) {
        let newData = date.split("T")[0];
        return newData + "T" + time;
      }
      return date + "T" + time;
    },
    async fetchPreferredData() {
      if (this.autoFillPrefeeredEntityData) {
        if(this.autoFillPrefeeredEntityData == 'NOT_APPLY'){
          this.preferred_data = [];
        }else{
          this.preferred_data =
          typeof this.autoFillPrefeeredEntityData == "string"
            ? [this.autoFillPrefeeredEntityData]
            : this.autoFillPrefeeredEntityData;
        }
        return;
      }
      if (this.preferred_filters && this.preferred_filters.length) {
        this.preferred_filters[0]["query_type"] = "AND";
        this.preferred_data = [];
        let filters = this.mapDateFilters(
          this.preferred_filters,this.data.parent_entityId,this.data.parent_entityDataId
        );
        filters = filters.map((e) => {
          if (
            e?.data_source == "self_field" &&
            e?.data_type == "RELATION" &&
            !e.value
          ) {
            e.value = "";
          }
          return e;
        });
        let params = {
          entity_id: this.entityId,
          template_fields_data: this.currentEntity.templates.map((template) => {
            return {
              template_id: template.template_id,
            };
          }),
          filters: filters,
          relationship: this.realtionshipFilter,
          limit: this.data.allow_multiple ? this.limit : 1,
          page: this.page,
        };
        if (
          this.currentEntity &&
          this.currentEntity.created_by &&
          this.currentEntity.company_id
        ) {
          params.user_id = this.currentEntity.created_by;
          params.company_id = this.currentEntity.company_id;
        }
        await this.$store.dispatch(
          "entities/fetchEntityRecordsForTable",
          params
        );
        if (
          this.getEntityRecordsForTable &&
          this.getEntityRecordsForTable.data
        ) {
          this.preferred_data = this.getEntityRecordsForTable.data.flatMap(
            (e) => e._id
          );
        }
      }
    },
    updateFilters() {
      this.filters = this.filters.map((e) => {
        if (
          e &&
          e.data_source == "self_field" &&
          e.value_field &&
          !e.primary_filter
        ) {
          e.value = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            e.value = this.parentFormData[e.value_field];
          }
          e.data_type = "RELATION";
        } else if (
          e &&
          e.data_source == "from_date" &&
          e.data_type == "WEEKDAYS" &&
          e.value_field
        ) {
          let key = e.value_field.includes("#")
            ? e.value_field.split("#")[1]
            : e.value_field;
          if (this.form[key]) {
            let date = new Date(this.form[key]).getDay();
            e.value = [this.weekDays[date]];
          } else {
            e.value = [];
          }
        } else if (
          e.field == "UNIQUE" &&
          this.data?.parent_entityId &&
          this.data?.template_id
        ) {
          e.entity_id =
            this.data?.parent_entityId +
            "#" +
            this.data?.template_id +
            "#" +
            this.data.key;
          if (this.data?.parent_entityDataId) {
            e.parent_entityDataId = this.data.parent_entityDataId;
          }
          if (e.unique_field) {
            if (e.unique_field.includes("#")) {
              e.unique_value = this.form[e.unique_field.split("#")[1]];
            } else {
              e.unique_value = this.form[e.unique_field];
            }
          }
          if (e.limit_field && e.limit_field.includes("#")) {
            e.current_limit_field = parseInt(
              this.form[e.limit_field.split("#")[1]]
            );
          }
        } else if (e.data_source == "TEMPLATE" && e.value_field) {
          let v = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            v = this.parentFormData[e.value_field];
          }
          if (
            (e.operator == "in" || e.operator == "nin") &&
            typeof v != "object"
          ) {
            e.value = [v];
          } else if (e.data_type == "NUMBER") {
            e.value = parseInt(v || 0);
          } else if (e?.data_type == "DATE") {
            //e.value = this.convertDate(v, "23:59:00.000Z");
            e.value = moment(v).utc()
          } else {
            e.value = v;
          }
        }
        return e;
      });
      this.fetchEntitiesDataForTable();
    },
    setRelationshipDetails() {
      this.relationshipsData = [...(this.getEntityRelationships || [])];

      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.form[this.data.key];
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.setCurrentEntityFilters(this.relationshipsData);
      // this.$store.dispatch(
      //   "entityRelationships/updateCurrentEntityRelationships",
      //   this.relationshipsData
      // );
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
    },
    setCurrentEntityFilters(currentEntityRelationships) {
      this.currentEntityRelationship = currentEntityRelationships.find(
        (e) =>
          e.child_entity_id == this.data.entity_id ||
          e.parent_entity_id == this.data.entity_id
      );

      this.currentEntityRelationship2 = (
        this.childRelationshipsData || []
      ).find(
        (e) =>
          e.child_entity_id == this.data.entity_id ||
          e.parent_entity_id == this.data.entity_id
      );

      if (this.currentEntityRelationship) {
        if (
          this.currentEntityRelationship.child_relation_type == "ONE" &&
          this.currentEntityRelationship.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else if (
          this.currentEntityRelationship.child_relation_type == "MANY" &&
          this.currentEntityRelationship.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship.parent_entity_id,
            relation_type: "MANY_ONE",
          };
        }
      }
      if (this.currentEntityRelationship2) {
        if (
          this.currentEntityRelationship2.child_relation_type == "ONE" &&
          this.currentEntityRelationship2.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship2.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship2.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship2.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else if (
          this.currentEntityRelationship2.child_relation_type == "MANY" &&
          this.currentEntityRelationship2.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship2.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship2.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship2.parent_entity_id,
            relation_type: "MANY_ONE",
          };
        }
      }
    },
    // checkReadonly() {
    //   if (this.data.readonly) {
    //     return true;
    //   }
    //   if (this.data.styles && this.data.styles.labelStyle) {
    //     if (this.data.styles.labelStyle == "hide") {
    //       this.showLabel = false;
    //     }
    //     if (this.data.styles.labelStyle == "right") {
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   //If it's an entity varaible, have to check access
    //   if(this.data.field_assignable === 'read_only' && this.data.is_entity_variable){
    //     return true
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.data.readonly) {
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if (this.data.styles.labelStyle == "right") {
        //   this.isDefalutPos = false;
        // }
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "READ ONLY"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    primaryDataForLabel(data) {
      let representData = "";
      this.primaryFields.forEach((field) => {
        const key = field.template_id + "#" + field.key;
        representData += data[key] + " ";
      });
      return representData;
    },

    getEntitiesDataForView(entitiesValues) {
      return this.getLabel(
        this.entitiesData.find((e) => e._id == entitiesValues)
      );
    },
    getSearchPrimaryFields(index) {
      let result = [];
      if (
        this.currentEntity?.primaryFields?.[index]?.template_id &&
        this.currentEntity?.primaryFields?.[index]?.key
      ) {
        result = [
          this.currentEntity?.primaryFields?.[index]?.template_id +
            "." +
            this.currentEntity?.primaryFields?.[index]?.key,
        ];
      }
      return result;
    },
    async applyFieldsData(){
      if (this.data?.allow_multiple) {
        let selectedDatas = this.entitiesData.filter(
          (e) => this.form[this.data.key].indexOf(e._id) != -1
        );
        if (!selectedDatas || !selectedDatas.length) {
          this.form[this.data.key] = [];
        }
      } else {
        //have to check is existed data or not & updated entity variables
        let index = this.entitiesData.findIndex(
          (e) => e._id == this.form[this.data.key]
        );
        if (index > -1) {
          let label = this.getLabel(this.entitiesData[index], 0);
          this.$set(this.form, this.data.key + "/name", label);
          await this.checkEntityFields(this.entitiesData[index]?._id, true);
        } else {
          this.form[this.data.key] = "";
        }
      }
    },
    async fetchEntitiesDataForTable(dataIds = []) {
      if (
        this.autoFillEntityData &&
        this.autoFillEntityData.data &&
        this.autoFillEntityData.update
      ) {
        this.entitiesData = this.autoFillEntityData.data;
        await this.applyFieldsData();
        this.loading = false;
        return;
      }
      this.loading = false;
      this.optionsLoading = true;
      this.hasMore = false;
      this.showWarningText = "";
      let params = {
        entity_id: this.entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.removeOptionalFilters(this.addGlobalFilters(this.filters || [])), this.data.parent_entityId,this.data.parent_entityDataId
        ),
        relationship: this.realtionshipFilter,
        limit: this.limit,
        page: this.page,
        ignore_populate: true
      };
      if (this.search_string) {
        params.search_string = this.search_string;
        params.primary_search_fields = this.getSearchPrimaryFields(0);
      }
      if (dataIds.length) {
        params.entity_data_ids = dataIds;
      }
      if (this.dataIds.length) {
        params.entity_data_ids = this.dataIds;
      }
      let relatedToSelfFilter = params.filters.findIndex(e => e.field == 'self#related_to/self')
      if(relatedToSelfFilter != -1){
        if(this.data.parent_entityId == this.data.entity_id){
          params.entity_data_ids = [this.data.parent_entityDataId]
        }
        params.filters.splice(relatedToSelfFilter, 1)
      }
      if (this.company_id) {
        params.company_id = this.company_id;
      }
      if (this.data?.entity_sort) {
        let [order, by] = this.data.entity_sort.split("/");
        params.sortBy = by;
        params.sortOrder = order;
      }
      if (
        this.currentEntity &&
        this.currentEntity.created_by &&
        this.currentEntity.company_id
      ) {
        params.user_id = this.currentEntity.created_by;
        params.company_id = this.currentEntity.company_id;
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.isSearching || !this.loadMore) {
        this.entitiesData = [];
      }
      this.update_from_parent = false;
      this.loadMore = false;
      if (this.getEntityRecordsForTable) {
        this.hasMore = this.getEntityRecordsForTable.hasMore;
        this.entitiesData = [
          ...this.entitiesData,
          ...this.getEntityRecordsForTable.data,
        ];
        if (!this.data.allow_multiple) {
          let checkForSelectedData = this.entitiesData.find(
            (e) =>
              e &&
              this.form &&
              this.data.key &&
              e._id == this.form[this.data.key]
          );
          if (!checkForSelectedData && this.form[this.data.key]) {
            this.form[this.data.key] = "";
          }
        }
        // this.loading = false;
        this.optionsLoading = false;
        if (
          this.preferred_filters &&
          this.preferred_filters.length &&
          !this.form[this.data.key]
        ) {
          await this.fetchPreferredData();
          if (this.preferred_data.length && !this.data.data_table_auto_fill) {
            if (this.data?.allow_multiple) {
              this.$set(this.form, this.data.key, this.preferred_data);
              await this.checkEntityFields(this.preferred_data, true);
            } else {
              this.$set(this.form, this.data.key, this.preferred_data[0]);
              let label = this.getLabel(this.preferred_data[0], 0);
              this.$set(this.form, this.data.key + "/name", label);
              await this.checkEntityFields(this.preferred_data[0], true);
            }
          }
        } else if (
          this.data.default_entity_data_ids &&
          this.data.default_entity_data_ids.length
        ) {
          this.entitiesData = this.entitiesData.filter((e) => {
            return this.data.default_entity_data_ids.indexOf(e._id) > -1;
          });
          if (!this.data.parent_entity_related) {
            if (this.data?.allow_multiple) {
              this.$set(this.form, this.data.key, [
                this.data.default_entity_data_ids[0],
              ]);
            } else {
              this.$set(
                this.form,
                this.data.key,
                this.data.default_entity_data_ids[0]
              );
            }
            //have to check is existed data or not & updated entity variables
            let index = this.entitiesData.findIndex(
              (e) => e._id == this.form[this.data.key]
            );
            if (index > -1) {
              let label = this.getLabel(this.entitiesData[index], 0);
              this.$set(this.form, this.data.key + "/name", label);
              await this.checkEntityFields(
                this.data.default_entity_data_ids[0],
                true
              );
            } else {
              this.form[this.data.key] = "";
            }
            this.$emit("entityDataUpdated",this.entitiesData.find(e => e._id == this.form[this.data.key]),this.data,this.form[this.data.key + '/name'],true)
          }
        } else if (
          this.entitiesData.length == 1 &&
          this.data.auto_select_one_data
        ) {
          if (this.data?.allow_multiple) {
            this.$set(this.form, this.data.key, [this.entitiesData[0]._id]);
            await this.checkEntityFields([this.entitiesData[0]._id], true);
          } else {
            this.$set(this.form, this.data.key, this.entitiesData[0]._id);
            await this.checkEntityFields(this.entitiesData[0]._id, true);
          }
          let label = this.getLabel(this.entitiesData[0], 0);
          this.$set(this.form, this.data.key + "/name", label);
        } else if (this.form[this.data.key]) {
          await this.applyFieldsData();
        }
        if (this.data.allow_multiple && this.data.auto_complete) {
          this.$set(
            this.form,
            this.data.key,
            this.entitiesData.flatMap((e) => e._id)
          );
          await this.checkEntityFields(this.entitiesData.flatMap((e) => e._id),true);
        }
        if (!this.entitiesData.length) {
          this.$emit("clearEntityFields", this.data, this.dataTableRowIndex);
          bus.$emit("clearEntityFields", this.data);
        } else {
          if (
            this.checkGlobalFilter &&
            this.getGlobalFiltervalue &&
            this.entitiesData.find((e) => e._id == this.getGlobalFiltervalue) &&
            (!this.form[this.data.key] || !this.form[this.data.key].length)
          ) {
            if (this.data?.allow_multiple) {
              this.$set(this.form, this.data.key, [this.getGlobalFiltervalue]);
              await this.checkEntityFields([this.getGlobalFiltervalue], true);
            } else {
              this.$set(this.form, this.data.key, this.getGlobalFiltervalue);
              let label = this.getLabel(this.getGlobalFiltervalue, 0);
              this.$set(this.form, this.data.key + "/name", label);
              await this.checkEntityFields(this.getGlobalFiltervalue, true);
            }
          }
        }
      } else {
        // this.loading = false;
        this.optionsLoading = false;
      }
      // this.populatedEntitiesData = {
      //   ...this.getEntityRecordsForTableWithHash[this.entityId],
      // };

      // this.entitiesData = this.modifyDataIntoFields(
      //   this.populatedEntitiesData.data
      // );
    },
    addGlobalFilters(filters) {
      if (this.checkGlobalFilter?.apply_on && this.getGlobalFiltervalue) {
        let field = this.checkGlobalFilter.apply_on.find(
          (e) => e.split("#")[0] == this.data.parent_entityId
        );
        if (field) {
          let [entity, template, key] = field.split("#");
          if (
            entity &&
            template &&
            key &&
            !filters.find((e) => e.field == template + "#" + key)
          ) {
            if (!filters.length) {
              filters.push({
                field: "_id",
                operator: "exists",
                value: "",
                value_type: "",
                value_source: "",
                data_type: "OBJECT_ID",
                query_type: "OR",
                value_field: "",
                validations: {
                  data_type: "",
                },
                relation_entity_id: entity,
                data_source: "ENTITY_DATA_LIST",
                current_date: this.getCurrentDate,
              });
            }
            filters.push({
              field: "_id",
              operator: this.checkGlobalFilter.operator,
              value: [this.getGlobalFiltervalue],
              value_type: "",
              value_source: "",
              data_type: "OBJECT_ID",
              query_type: "OR",
              value_field: "",
              validations: {
                data_type: "",
              },
              relation_entity_id: entity,
              data_source: "ENTITY_DATA_LIST",
              current_date: this.getCurrentDate,
            });
          }
        }
      }
      return filters;
    },
    async checkEntityFields(data, changed = false, dataTables = []) {
      try {
        if (
          !this.data?.allow_multiple &&
          data &&
          typeof data == "object" &&
          data.length > 0
        ) {
          data = data[0];
        }
        if (data && data.length) {
          if (this.data?.allow_multiple) {
            if (data.indexOf("LOADMORE") != -1) {
              this.page++;
              this.form[this.data.key] = data.splice(
                data.indexOf("LOADMORE"),
                1
              );
              this.loadMore = true;
              this.fetchEntitiesDataForTable();
              return;
            } else if (data.indexOf("ALL") != -1) {
              this.form[this.data.key] = data.splice(data.indexOf("ALL"), 1);
              data = this.entitiesData.flatMap((e) => e._id);
              this.form[this.data.key] = [];
              this.form[this.data.key] = data;
            }
            let selectedDatas = this.entitiesData.filter(
              (e) => data.indexOf(e._id) != -1
            );
            let label = "";
            selectedDatas = selectedDatas.map((selectedData) => {
              let cDataLable = this.getLabel(selectedData, 0);
              if (!label) {
                label = label + cDataLable;
              } else {
                label = label + "," + cDataLable;
              }
              selectedData.parentDataName = cDataLable;
              return selectedData;
            });
            if (label) {
              this.$set(this.form, this.data.key + "/name", label);
            }
            if (this.data.detailed_view && selectedDatas.length) {
              //have to reset data tables if we have detailed view entity
              if (dataTables && dataTables.length) {
                dataTables.forEach((e) => {
                  this.$set(this.form, e.key, []);
                });
              }
              this.$set(
                this.form,
                this.data.key,
                selectedDatas.flatMap((e) => e._id)
              );
            }
            if (!this.isViewCopy) {
              if (this.dataTableRowIndex > -1) {
                this.$emit(
                  "entityDataUpdated",
                  selectedDatas,
                  this.data,
                  label,
                  changed,
                  this.dataTableRowIndex,
                  this.index
                );
              } else {
                // need to check data
                this.$emit(
                  "entityDataUpdated",
                  selectedDatas,
                  this.data,
                  label,
                  changed,
                  this.checkReadonly(),
                  this.index
                );
              }
            }
          } else {
            if (data == "LOADMORE") {
              this.page++;
              this.form[this.data.key] = "";
              this.loadMore = true;
              this.fetchEntitiesDataForTable();
              return;
            } else if (data == "ALL") {
              return;
            }
            let selectedData = this.entitiesData.find((e) => e._id == data);
            let label = this.getLabel(selectedData, 0);
            this.$set(this.form, this.data.key, data);
            if (!this.isViewCopy) {
              if (this.dataTableRowIndex > -1) {
                this.$emit(
                  "entityDataUpdated",
                  selectedData,
                  this.data,
                  label,
                  changed,
                  this.dataTableRowIndex,
                  this.index
                );
              } else {
                // need to check data
                this.$emit(
                  "entityDataUpdated",
                  selectedData,
                  this.data,
                  label,
                  changed,
                  this.checkReadonly(),
                  this.index
                );

                //Bus emit for Data table when it is entity Variable start
                let entityDataTableFieldCount = this.lodash.filter(
                  this.fieldsData,
                  function (element) {
                    return (
                      element.input_type === "ENTITY_VARIABLE" &&
                      element.inputType == "DATA_TABLE"
                    );
                  }
                );
                if (
                  entityDataTableFieldCount &&
                  entityDataTableFieldCount.length &&
                  changed == true
                ) {
                  // bus.$emit(
                  //   "entityDataUpdated",
                  //   selectedData,
                  //   this.data,
                  //   label,
                  //   changed
                  // );
                }
                //Bus emit for Data table when it is entity Variable End
              }
              if (label) {
                this.$set(this.form, this.data.key + "/name", label);
              }
            }
          }
        } else {
          this.$emit("clearEntityFields", this.data, this.dataTableRowIndex);
          bus.$emit("clearEntityFields", this.data);
        }
        if (changed) {
          this.rulesEventEmitter();
        }
      } catch (err) {
        console.log(data);
      }
    },

    async fetchEntityData() {
      this.loading = true;
      let params = { get_all: true };
      if (this.company_id && this.document_id) {
        params = {
          ...params,
          ...{ company_id: this.company_id, document_id: this.document_id },
        };
      }
      let id = this.entityId;
      await this.$store.dispatch("entities/fetchEntityDataById", {
        id,
        params,
      });
      this.entitiesData = [];
      if (this.getEntityDataByEntityId && this.getEntityDataByEntityId.data) {
        this.entitiesData = this.getEntityDataByEntityId.data;
      }
      // await this.$store.dispatch(
      //   "companyEntities/fetchSingleCompanyEntity",
      //   this.entityId
      // );

      // this.currentEntity = {
      //   ...this.getSingleCompanyEntityWithHash[this.entityId],
      // };

      // this.primaryFields = this.currentEntity.primary_fields;

      // this.templatesData = this.currentEntity.sections.map((section) => {
      //   let template = section.template_id;

      //   let fields = template.sections[0].fields;

      //   let modifiedKeyFields = fields.map((field) => {
      //     field.key = template._id + "#" + field.key;
      //     field.table_display = true;
      //     return field;
      //   });

      //   this.templateFields.push(modifiedKeyFields);

      //   return {
      //     template_id: template._id,
      //     fields: fields,
      //     template_name: template.name,
      //   };
      // });

      // this.templateFields = [].concat(...this.templateFields);

      // if (!this.isEntityField) {
      //   await this.fetchEntitiesDataForTable();
      // }

      this.loading = false;
    },

    modifyDataIntoFields(enityData) {
      return enityData.map((e, i) => {
        let details = Object.assign({}, ...e);
        details.represent_data = this.getLabel(details, i);
        return details;
      });
    },
    getField(key) {
      if (key && this.allEntityFields && this.allEntityFields.length) {
        return this.allEntityFields.find((e) => e.key == key);
      }
      return null;
    },
    getLabel(item, i) {
      let name = "";
      this.getEntityPrimaryFields.forEach((pr, index) => {
        let field = this.getField(pr);
        if (
          field &&
          field.inputType != "DATA_TABLE" &&
          index < 3 &&
          this.getPrimaryName(field, item)
        ) {
          let separator = "";
          if (index != 0) {
            separator = " - ";
          }
          name = name + separator + this.getPrimaryName(field, item);
        }
      });
      if (!name) name = "Data " + i;
      return name;
    },
    async fetchFieldData(result) {
      try {
        if (result.length) {
          let entityDataIds = result.map((e) => e.entity_data_id);
          let entityIds = result.map((e) => e.entity_id);

          const params = {
            entity_data_ids: entityDataIds,
            entity_id: entityIds[0],
          };

          await this.$store.dispatch(
            "entitiesData/fetchEntitiesFieldsData",
            params
          );

          let dataIds = [];

          let details = this.getEntityRecordsForFieldsWithHash[entityIds[0]];
          const key = this.parentEntityField.key.replace(
            this.parentEntityField.section_id + "#",
            ""
          );

          details.forEach((templatesData) => {
            templatesData.forEach((templateData) => {
              let values = templateData.template_data[key].map(
                (e) => e.entity_data_id
              );
              dataIds = [...dataIds, ...values];
            });
          });

          if (dataIds.length) {
            await this.fetchEntitiesDataForTable(dataIds);
          }
        } else if (this.form[this.data.key].length) {
          this.$set(this.form, this.data.key, []);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async setQuickAddEntityDataAsValue(entityData) {
      this.$set(this.form, this.data.key, entityData.entity_data_id);
      this.checkEntityFields(entityData.entity_data_id);
      await this.fetchEntitiesDataForTable();
    },
    enableQRReader(){
      this.qrLoader=true;
      this.showQrReader = true;
      setTimeout(() => {
        this.qrLoader=false;
      }, 1500);
    },
    cancelQRPopup() {
      this.loading=true;
      this.showQrReader=false;

      navigator.mediaDevices.getUserMedia({ video: true })

      navigator.mediaDevices.getUserMedia({video: true, audio: false})
                .then(mediaStream => {
                  console.log("Media stream",mediaStream)
                    const stream = mediaStream;
                    const tracks = stream.getTracks();
                    console.log("tracks",tracks)
                    tracks.forEach(track => track.stop())
                })
      // this.modalTitle='';
      setTimeout(() => {
        this.loading=false;
      }, 1500);
    }
  },

  watch: {
    form: {
      async handler(data) {
        if (
          data[this.data.key + "/name"] &&
          JSON.stringify(this.default_data_ids) !=
            JSON.stringify(data[this.data.key + "/name"])
        ) {
          this.default_data_ids = [];
          this.default_data_ids = data[this.data.key + "/name"];
          this.refresh = true;
          setTimeout(() => {
            this.refresh = false;
          });
        }
        if (this.isViewCopy) {
          return;
        }
        if (
          data[this.data.key] &&
          !this.areArgumentsEqual(
            data[this.data.key],
            this.previousFormData[this.data.key]
          )
        ) {
          this.previousFormData = {
            ...this.previousFormData,
            ...JSON.parse(JSON.stringify(data)),
          };
          this.checkEntityFields(data[this.data.key], true);
        }
        let newFilterData = {};
        if (this.filters) {
          this.filters.forEach((e) => {
            if (e.data_source == "parent_entity_data_id") {
              this.$set(newFilterData, e.value_field, e.value);
            } else if (
              e.limit_field &&
              e.limit_field.includes("#") &&
              e.field == "UNIQUE"
            ) {
              this.$set(
                newFilterData,
                e.limit_field.split("#")[1],
                data[e.limit_field.split("#")[1]]
              );
            } else if (e.unique_field) {
              if (e.unique_field.includes("#")) {
                this.$set(
                  newFilterData,
                  e.unique_field.split("#")[1],
                  data[e.unique_field.split("#")[1]]
                );
              } else {
                this.$set(newFilterData, e.unique_field, data[e.unique_field]);
              }
            }
            // else if (e.field == "RESOURCE_MANAGEMENT") {
            //   if (e.value_field.includes("#")) {
            //     this.$set(
            //       newFilterData,
            //       e.value_field.split("#")[1],
            //       data[e.value_field.split("#")[1]]
            //     );
            //   } else {
            //     this.$set(newFilterData, e.value_field, data[e.value_field]);
            //   }
            // }
            else if (e.field != "UNIQUE") {
              if (
                (e.operator == "in" || e.operator == "nin") &&
                typeof data[e.value_field] != "object"
              ) {
                this.$set(newFilterData, e.value_field, [data[e.value_field]]);
              } else {
                this.$set(newFilterData, e.value_field, data[e.value_field]);
              }
            }
          });
          if (
            this.data.default_entity_data_ids &&
            this.data.default_entity_data_ids.length
          ) {
            this.filters = this.filters.filter(
              (filter) => filter.field.indexOf("self#related_to") > -1
            );
          }
          if (
            JSON.stringify(newFilterData) != JSON.stringify(this.filtersData)
          ) {
            this.getValue(data);
          }

          if (this.filters) {
            this.filters.forEach((e) => {
              if (e.field != "UNIQUE") {
                if (
                  (e.operator == "in" || e.operator == "nin") &&
                  typeof data[e.value_field] != "object"
                ) {
                  this.$set(this.filtersData, e.value_field, [
                    data[e.value_field],
                  ]);
                } else {
                  this.$set(
                    this.filtersData,
                    e.value_field,
                    data[e.value_field]
                  );
                }
              } else if (e.unique_field) {
                if (e.unique_field.includes("#")) {
                  this.$set(
                    this.filtersData,
                    e.unique_field.split("#")[1],
                    data[e.unique_field.split("#")[1]]
                  );
                } else {
                  this.$set(
                    this.filtersData,
                    e.unique_field,
                    data[e.unique_field]
                  );
                }
                if (e.limit_field && e.limit_field.includes("#")) {
                  this.$set(
                    this.filtersData,
                    e.limit_field.split("#")[1],
                    data[e.limit_field.split("#")[1]]
                  );
                }
              }
            });
          }
        }
        if (
          this.resourceFilter?.value_field &&
          this.resourceFilter.value_field.includes("#")
        ) {
          let key = this.resourceFilter.value_field.split("#")[1];
          if (
            this.resourceFilterData !== this.form[key] &&
            !isNaN(this.form[key])
          ) {
            let vField =
              this.resourceFilter &&
              this.resourceFilter.data_source == "TEMPLATE" &&
              this.resourceFilter.value_field &&
              this.resourceFilter.value_field.includes("#")
                ? this.resourceFilter.value_field.split("#")[1]
                : null;
            this.resourceFilter.value = this.form[vField];
            let variable,
              { value, selectedData } = this.getInventoryData(
                this.entitiesData,
                this.resourceFilter,
                this.form
              );
            if (this.data?.data_table_key) {
              this.fieldsData.forEach((f) => {
                if (f.key == this.data.data_table_key && f.data_table_columns) {
                  variable = f.data_table_columns.find(
                    (e) =>
                      e.relationship_key == this.data.key &&
                      e.variable_action == "RESOURCE_AVERAGE"
                  );
                }
              });
            } else {
              variable = this.fieldsData.find(
                (e) =>
                  e.relationship_key == this.data.key &&
                  e.variable_action == "RESOURCE_AVERAGE"
              );
            }
            if (variable?.key) {
              this.$set(this.form, variable.key, value);
              this.$set(
                this.form,
                variable.key + "?selectedData",
                selectedData
              );
            }
            this.resourceFilterData = this.form[key];
          }
        }
      },
      deep: true,
    },
    parentFormData: {
      async handler(data) {
        if (this.dataTableRowIndex > -1) {
          if (this.data.relationship_key) {
            if (
              data[this.data.relationship_key] !==
              this.previousParentFormData[this.data.relationship_key]
            ) {
              this.previousParentFormData = {
                ...this.previousFormData,
                ...JSON.parse(JSON.stringify(data)),
              };

              this.checkEntityFields(data[this.data.key]);

              let newFilterData = {};
              if (this.filters) {
                this.filters.forEach((e) => {
                  if (e.data_source == "parent_entity_data_id") {
                    this.$set(newFilterData, e.value_field, e.value);
                  } else {
                    this.$set(
                      newFilterData,
                      e.value_field,
                      data[e.value_field]
                    );
                  }
                });

                if (
                  this.data.default_entity_data_ids &&
                  this.data.default_entity_data_ids.length
                ) {
                  this.filters = this.filters.filter(
                    (filter) => filter.field.indexOf("self#related_to") > -1
                  );
                }
                if (
                  JSON.stringify(newFilterData) !=
                  JSON.stringify(this.filtersData)
                ) {
                  this.getValue(data);
                }

                if (this.filters) {
                  this.filters.forEach((e) =>
                    this.$set(
                      this.filtersData,
                      e.value_field,
                      data[e.value_field]
                    )
                  );
                }
              }
            }
          } else {
            let newFilterData = {};
            this.filters.forEach((e) => {
              if (e.data_source == "parent_entity_data_id") {
                this.$set(newFilterData, e.value_field, e.value);
              } else if (
                e.limit_field &&
                e.limit_field.includes("#") &&
                e.field == "UNIQUE"
              ) {
                this.$set(
                  newFilterData,
                  e.limit_field.split("#")[1],
                  data[e.limit_field.split("#")[1]]
                );
              } else if (e.unique_field) {
                if (e.unique_field.includes("#")) {
                  this.$set(
                    newFilterData,
                    e.unique_field.split("#")[1],
                    data[e.unique_field.split("#")[1]]
                  );
                } else {
                  this.$set(
                    newFilterData,
                    e.unique_field,
                    data[e.unique_field]
                  );
                }
              } else if (e.field != "UNIQUE") {
                if (
                  (e.operator == "in" || e.operator == "nin") &&
                  typeof data[e.value_field] != "object"
                ) {
                  this.$set(newFilterData, e.value_field, [
                    data[e.value_field],
                  ]);
                } else {
                  this.$set(newFilterData, e.value_field, data[e.value_field]);
                }
              }
            });
            if (
              JSON.stringify(newFilterData) !=
              JSON.stringify(this.parentFiltersData)
            ) {
              this.getValue(data);
            }

            if (this.filters) {
              this.filters.forEach((e) => {
                if (e.field != "UNIQUE") {
                  if (
                    (e.operator == "in" || e.operator == "nin") &&
                    typeof data[e.value_field] != "object"
                  ) {
                    this.$set(this.parentFiltersData, e.value_field, [
                      data[e.value_field],
                    ]);
                  } else {
                    this.$set(
                      this.parentFiltersData,
                      e.value_field,
                      data[e.value_field]
                    );
                  }
                } else if (e.unique_field) {
                  if (e.unique_field.includes("#")) {
                    this.$set(
                      this.parentFiltersData,
                      e.unique_field.split("#")[1],
                      data[e.unique_field.split("#")[1]]
                    );
                  } else {
                    this.$set(
                      this.parentFiltersData,
                      e.unique_field,
                      data[e.unique_field]
                    );
                  }
                  if (e.limit_field && e.limit_field.includes("#")) {
                    this.$set(
                      this.parentFiltersData,
                      e.limit_field.split("#")[1],
                      data[e.limit_field.split("#")[1]]
                    );
                  }
                }
              });
            }
          }
        }
      },
      deep: true,
    },
    // form: {
    //   async handler(data) {
    //     if (this.parentEntityId) {
    //       let result = data["parent_data"][this.parentEntityId];
    //       await this.fetchFieldData(result);
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
.qrCodeBlock{
  text-align: center;
  padding: 0.2rem;

  .size-2em{
    font-size: 2em !important;
  }
  
}
.highlight-border .el-input__inner {
    border-color: #ff4040 !important;
}
.el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow-x: hidden;
}
</style>
